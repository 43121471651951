export const splitEANs = (line) => {
    const eanRegex = /\b\d{13}\b/g; // Busca números de 13 dígitos
    const matches = [];
    let lastIndex = 0;

    let match;
    while ((match = eanRegex.exec(line)) !== null) {
        // Agrega texto previo al EAN
        if (match.index > lastIndex) {
            matches.push(line.substring(lastIndex, match.index).trim());
        }

        // Agrega el EAN
        matches.push(match[0]);

        // Actualiza el índice de inicio
        lastIndex = match.index + match[0].length;
    }

    // Agrega cualquier texto restante después del último EAN
    if (lastIndex < line.length) {
        matches.push(line.substring(lastIndex).trim());
    }

    return matches.filter(Boolean); // Filtra partes vacías
};