import { combineLines } from '../utils/combineLines';
import extractLinesFromTextContent from '../utils/extractText';

const CENCOSUDParser = async (pdf) => {
    let orderItems = [];
    const numPages = pdf.numPages;

    for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const lines = extractLinesFromTextContent(textContent);

        console.log(`Líneas extraídas en la página ${i}:`, lines);

        let tableStarted = false;

        lines.forEach(line => {
            const eanRegex = /\b\d{13}\b/; // Busca un número de 13 dígitos
            const match = line.match(eanRegex);

            if (match) {
                const detailsArray = line.split(/\s+/);

                // Asegúrate de validar y asignar correctamente los valores
                const ean = match[0]; // Número EAN encontrado

                // Evita el número 7795254057797
                if (ean === '7795254057797') {
                    return; // Salta esta línea y no la procesa
                }

                const cantPed = parseFloat(detailsArray[detailsArray.length - 9]) || 0; 
                const uc = parseInt(detailsArray[detailsArray.length - 10]) || 0;
                const precio = parseFloat(detailsArray[detailsArray.length - 6]) || 0; 

                orderItems.push({ ean, cantPed, uc, precio });
            }
        });
    }

    return orderItems;
};

export default CENCOSUDParser;