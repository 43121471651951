import { extractLinesFromTextContentDia } from '../utils/extractText';
import { combineLines } from '../utils/combineLines';

const DIAParser = async (pdf) => {
    // let orderItems = [];
    // const numPages = pdf.numPages;

    // for (let i = 1; i <= numPages; i++) {
    //     const page = await pdf.getPage(i);
    //     const textContent = await page.getTextContent();
    //     const rawLines = extractLinesFromTextContent(textContent);

    //     console.log(`Líneas extraídas en la página ${i}:`, rawLines);

    //     const lines = combineLines(rawLines);

    //     let tableStarted = false;

    //     lines.forEach((line) => {
    //         if (!tableStarted && line.includes('ARTICULO')) {
    //             tableStarted = true;
    //             return;
    //         }

    //         if (tableStarted) {
    //             const detailsArray = line.split(/\s+/);

    //             if (/^\d{13}$/.test(detailsArray[0]) && detailsArray.length > 3 && detailsArray[0] !== '7799112005142') {
    //                 orderItems.push({
    //                     ean: detailsArray[0],
    //                     cantPed: 0,
    //                     precio: 0
    //                 });
    //             }
    //         }
    //     });
    // }

    let orderItems = [];
    const numPages = pdf.numPages;

    for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const rawLines = extractLinesFromTextContentDia(textContent);

        console.log(`Líneas extraídas en la página ${i}:`, rawLines);

        // Combina líneas fragmentadas
        const lines = combineLines(rawLines);

        let tableStarted = false;

        lines.forEach(line => {
            const eanRegex = /\b\d{13}\b/; // Busca un número de 13 dígitos
            const match = line.match(eanRegex);

            if (match) {
                const detailsArray = line.split(/\s+/);

                // Asegúrate de validar y asignar correctamente los valores
                const ean = match[0]; // Número EAN encontrado

                if (ean === '7799112005142') {
                    return; // Salta esta línea y no la procesa
                }

                // const cantPed = parseFloat(detailsArray[detailsArray.length - 9]) || 0; 
                // const uc = parseInt(detailsArray[detailsArray.length - 10]) || 0;
                // const precio = parseFloat(detailsArray[detailsArray.length - 6]) || 0; 
                // Extrae cantidades y precios, ajusta los índices según el formato del archivo
                const cantPedIndex = detailsArray.indexOf(ean) + 2; // Cantidad pedida (dos posiciones después del EAN)
                const precioIndex = detailsArray.indexOf(ean) + 6; // Precio (seis posiciones después del EAN)
                const ucIndex = detailsArray.indexOf(ean) + 3; // Precio (seis posiciones después del EAN)

                const cantPed = parseFloat(detailsArray[cantPedIndex]) || 0;
                const precio = parseFloat(detailsArray[precioIndex]) || 0;
                const uc = parseInt(detailsArray[ucIndex]) || 0;

                orderItems.push({ ean, cantPed, uc, precio });
            }
        });
    }

    return orderItems;
};

export default DIAParser;