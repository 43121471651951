export const combineLines = (lines) => {
    const combinedLines = [];
    let currentLine = '';

    lines.forEach((line) => {
        // Si la línea contiene un EAN (13 dígitos), asumimos que comienza una nueva fila
        if (/^\d{13}$/.test(line)) {
            if (currentLine.trim()) {
                combinedLines.push(currentLine.trim());
            }
            currentLine = line; // Inicia una nueva línea
        } else {
            currentLine += ' ' + line; // Continúa acumulando en la línea actual
        }
    });

    // Agregar la última línea si existe
    if (currentLine.trim()) {
        combinedLines.push(currentLine.trim());
    }

    return combinedLines;
};