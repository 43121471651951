import { splitEANs } from '../utils/splitEANs';

const extractLinesFromTextContent = (textContent) => {
    const lines = [];
    let currentLine = '';
    let lastY = null;

    textContent.items.forEach((item) => {
        const { str, transform } = item;

        if (!transform || transform.length < 6) return;

        const y = transform[5];
        if (lastY === null || Math.abs(y - lastY) > 2) {
            if (currentLine.trim()) lines.push(currentLine.trim());
            currentLine = str;
        } else {
            currentLine += ' ' + str;
        }

        lastY = y;
    });

    if (currentLine.trim()) lines.push(currentLine.trim());
    return lines;
};

const extractLinesFromTextContentDia = (textContent) => {
    const lines = [];
    let currentLine = '';
    let lastY = null;
    const LINE_BREAK_THRESHOLD = 5; // Ajusta según el espaciado entre líneas del PDF.

    textContent.items.forEach(item => {
        const { str, transform } = item;

        if (!str || typeof str !== 'string' || !transform || transform.length < 6) return;

        const y = transform[5];

        // Detecta un cambio de línea basado en la posición Y
        if (lastY === null || Math.abs(y - lastY) > LINE_BREAK_THRESHOLD) {
            if (currentLine.trim()) {
                // lines.push(currentLine.trim());
                // Procesa la línea para separar EAN y otros datos
                const processedLines = splitEANs(currentLine.trim());
                lines.push(...processedLines);
            }
            currentLine = str; // Inicia una nueva línea
        } else {
            currentLine += ' ' + str; // Agrega al contenido actual
        }

        lastY = y;
    });


    if (currentLine.trim()) {
        // Procesa la última línea
        const processedLines = splitEANs(currentLine.trim());
        lines.push(...processedLines);
    }

    return lines;
};

export default extractLinesFromTextContent;
export { extractLinesFromTextContentDia };