import extractLinesFromTextContent from '../utils/extractText';

const INCParser = async (pdf) => {
    let orderItems = [];
    const numPages = pdf.numPages;

    for (let i = 1; i <= numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        const lines = extractLinesFromTextContent(textContent);

        let tableStarted = false;

        lines.forEach(line => {
            if (!tableStarted && line.includes('EAN')) {
                tableStarted = true;
                return;
            }

            if (tableStarted) {
                const detailsArray = line.split(/\s+/);
                if (detailsArray.length > 6) {
                    orderItems.push({
                        ean: detailsArray[0],
                        refProv: detailsArray[1],
                        descripcion: detailsArray.slice(2, -4).join(' '),
                        um: "",
                        uc: parseInt(detailsArray[detailsArray.length - 4], 10),
                        talle: "",
                        color: "",
                        cantPed: parseInt(detailsArray[detailsArray.length - 3], 10),
                        precio: parseFloat(detailsArray[detailsArray.length - 2]),
                        DESCUENTO1: 0,
                    });
                }
            }
        });
    }

    return orderItems;
};

export default INCParser;